<template>
  <embellishment-edit />
</template>

<script>
import EmbellishmentEdit from './EmbellishmentEdit.vue'

export default {
  components: {
    EmbellishmentEdit,
  },
}
</script>
